<template>
  <div class="col-lg-12">
    <hot-offer-icon class="hot-offer-icon" :color="item.badgeColor" :text="item.badgeText" v-if="isBadge"/>

    <div class="lesiurpackages_hotelsbox domestic_hotelsbox" :class="isBig && 'big'">
      <img :src="imageUrl"
        width=""
        height=""
        :alt="item.hotelName"
        v-bind="mainProps"
        onerror="this.src='assets/img/lesiurpackages_hotelsimg6.jpg'"
      />
      <div class="disc" v-if="(isOdysseySite && isFCLAgent && discountPercent > 0 && !isBadge) || (!isOdysseySite && discountPercent > 0 && !isBadge)">
        <strong>{{discountPercent + memberDiscountPercent}}%</strong><br /> {{ $t("home.discount") }}
      </div>
      <div class="lesiurpackages_hotelstextbox">

        <h3 v-if="isOfirQuery">{{item.extendedProperties.sourceName}} - {{item.priority}}</h3>
        <h3> {{ item.hotelName }}, {{ item.extendedProperties.destination.name}}</h3>
        <p class="item-sub-title">
          <i class="fa fa-calendar-alt"></i> <span dir="ltr">{{ changeDateFormat(item.checkinDate) }} - {{ changeDateFormat(item.checkoutDate) }}</span>
        </p>
        <p class="remark" :style="isRTLPage ? 'direction: rtl' : 'direction: ltr'" v-if="remark">
          <i class="far fa-comment-dots"></i>
          {{ remark }}
        </p>

        <!-- <a :href="productLink"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></a> -->
        <a v-if="isLanding" :href="productPageLink"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></a>
        <router-link v-else :to="productPageLink"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></router-link>

        <div class="d-flex w-100 d-inline-block" :class="isRTLPage ? 'right-align' : 'left-align'">
          <div class="clock">
            <p><i class="fa fa-question-circle"></i> {{$t("home.what-including-in-price")}}</p>
            <p>
              <i class="fa fa-check"></i>
              {{$t("home.total-night")}}: {{ calcNight(item.checkoutDate, item.checkinDate) }} {{ (calcNight(item.checkoutDate, item.checkinDate) > 1) ? $t("home.nights") : $t("home.night") }}
           </p>
            <p><i class="fa fa-check"></i>{{ item.priceRemark }}</p>

          </div>
          <div class="price-wrapper align-items-center" :class="(device==='desktop') && isBig ? 'd-inline-flex': 'd-inline-block'" style="width:30%;">
            <p class="price">
              <span class="currency-symbol">₪</span><strong>{{discountedPrice}}</strong>
            </p>
            <p style="font-size:0.8rem;"> {{$t("home.total-price-for-the-package")}} </p>
            <p v-if="isOdysseySite && !isFCLAgent && (!isBig || device==='mobile')" class="mt-1">{{$t("home.this-order-has")}} <span>{{`${commission}%`}}</span></p>
          </div>
          <div class="align-self-md-end" v-if="isOdysseySite  && !isFCLAgent && (device==='desktop') && isBig">
            <p>{{$t("home.this-order-has")}} <span>{{`${commission}%`}}</span></p>
          </div>
        </div>
      </div>
    </div>

    <countdown class="collapse-button"
      :end-time="item.specialDiscount.endDate" @finish="finishCountdown" v-if="isTimer">
      <template v-slot:process="time">
        <h5>
          <b-badge variant="success" v-if="time.timeObj.d !== '0'">
            {{ $t("home.count-down-day", { day: time.timeObj.d, hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
          </b-badge>
          <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h !== '00'">
            {{ $t("home.count-down-hour", { hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
          </b-badge>
          <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h === '00' && time.timeObj.m !== '00'">
            {{ $t("home.count-down-minute", { minute: time.timeObj.m, second: time.timeObj.s}) }}
          </b-badge>
          <b-badge variant="success" v-else>
            {{ $t("home.count-down-second", { second: time.timeObj.s}) }}
          </b-badge>
        </h5>
      </template>
    </countdown>
    <MoreDealCollapse :index="item.id" :moreItems="moreItems" class="collapse-button" v-if="moreItems && moreItems.length > 0"/>
    <!-- <b-badge variant="warning" class="collapse-button" v-if="item.isSalesDeal && isSalesDealExpired">{{$t("home.count-down-expired")}}</b-badge> -->

  </div>
</template>
<script>
import { BBadge } from 'bootstrap-vue';
import TemplateLesiurtabItem from './TemplateLesiurtabItem';

export default {
  name: 'LesiurtabItemTheme0',
  mixins: [TemplateLesiurtabItem],
  components: {
    BBadge,
    HotOfferIcon: () => import('@/components/atoms/HotOfferIcon'),
    countdown: () => import('@/components/atoms/CountDown'),
    MoreDealCollapse: () => import('@/components/atoms/moreDealCollapse/MoreDealCollapseTheme0'),
  },
};
</script>

<style scoped>
.collapse-button {
  position: absolute;
  z-index: 99;
  left: 30px;
  top: 10px;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock{
  min-width: 70%;
}
.lesiurpackages_hotelsbox .right-align div.clock{
  border-left: 1px solid rgba(255, 255, 255, 0.37);
  direction: rtl;
}
.lesiurpackages_hotelsbox .left-align div.clock{
  border-left: none;
  margin-left: 0px;
  direction: ltr;
}
.lesiurpackages_hotelsbox .right-align div.price-wrapper{
  direction: rtl;
}
.lesiurpackages_hotelsbox .left-align div.price-wrapper{
  border-left: 1px solid rgba(255, 255, 255, 0.37);
  padding-left: 10px;
  direction: ltr;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p:first-child{
  margin-top: revert;
}
.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
  width:80%;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .item-sub-title{
  text-align:center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}
.price{
  padding-top: 20px;
}
.place {
  padding: 8px 10px;
  content: "";
  background: #005ca3;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  border-radius: 10px;
  z-index: 91;
  width: 120px;
  margin: 10px auto;
  border: 1px solid rgb(255 255 255 / 0.5);
  font-weight: bold;
}
.lesiurpackages_hotelstextbox a:hover {
  text-decoration: none;
}
.lesiurpackages_box.Oregenizedbox .lesiurpackages_hotelstextbox p {
  margin-bottom: 20px;
}

/* .domestic_hotelsbox{
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  background: whitesmoke;
  padding: 10px;
  box-shadow: #e0dede 0.5px 0.5px 5px;
}
.domestic_hotelsbox img{
  width: 100%;
  transition: 0.5s;
  object-fit: cover;
  height: 250px;
} */
.domestic-hotel-name{
  font-weight: bold;
  font-size: 1.3em;
}
.domestic_hotelstextbox{
  background: white;
  padding-bottom: 20px;
}
.last-paragraph{
  border-bottom: 1px solid;
}
.what-description{
  font-weight: bold;
}
.text-color-blue{
  color:#1425f5;
}
/* .domestic_hotelsbox .price strong {
    font-size: 4em;
}
.lesiurpackages_hotelsbox.big .currency-symbol, .domestic_hotelsbox .currency-symbol{
  font-size: 2em;
}
.domestic_hotelsbox .place{
  width: fit-content;
}
.domestic_hotelsbox .disc {
  position: absolute;
  top: 40px;
  left: calc(50px - 50%);
  font-size: 1.2em;
  line-height: 12px;
  box-sizing: border-box;
  padding: 8px 4px;
  color: #fff;
  z-index: 1;
  text-align: center;
  width: 100%;
  height: 25px;
  background: #f00;
  transform: rotate(-45deg);
  box-shadow: black 1px 1px 5px;
} */

.lesiurpackages_hotelsbox.domestic_hotelsbox .place {
  width: fit-content;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
  width: auto;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
  white-space: break-spaces;
  display: flex;
  margin-bottom: 3px;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox img {
  filter: brightness(0.8);
}
.domestic_hotelsbox.lesiurpackages_hotelsbox:hover img {
  filter: brightness(1);
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .d-inline-block p {
  font-size: 0.9rem;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock i {
  margin-left: 5px;
}
.img-content{
  overflow: hidden;
  position: relative;
}

.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .remark {
  font-size: 17px;
  color: #FFFFFF;
  direction: rtl;
  line-height: 15px;
  text-align: justify;
}
@media (max-width: 991px) {
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox {
    text-align: center;
  }
}
@media (min-width: 480px) {
  .lesiurpackages_hotelsbox.big .lesiurpackages_hotelstextbox .item-sub-title{
    margin-bottom: 130px;
  }
  .lesiurpackages_hotelsbox.big h3{
    font-size: 3em;
  }
  .lesiurpackages_hotelsbox.big .lesiurpackages_hotelstextbox .item-sub-title{
    font-size: 2em;
  }
  .lesiurpackages_hotelsbox.big .place{
    padding: 10px 10px;
    font-size: 1.2rem;
  }
  /* .lesiurpackages_hotelsbox:not(.big) .price, .domestic_hotelsbox .price{
    text-align: center;
  } */
  .lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox .d-inline-block p{
    line-height: 1em;
  }
}

  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
    white-space: nowrap;
  }
@media (max-width: 480px) {
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .d-inline-block p {
    font-size: 0.9em;
  }
  .lesiurpackages_hotelsbox {
    height: 350px;
  }
  /* .domestic_hotelsbox .domestic_hotelstextbox h4{
    font-size: 1.3rem;
  } */
  .lesiurpackages_hotelsbox.big .currency-symbol{
    font-size: initial;
  }
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox{
    padding: 4px
  }
}
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: -6px;
  right: 9px;
  position: absolute;
  /* transform: rotate(90deg); */
  z-index: 10;
}
</style>

<style>
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .domestic_hotelsbox.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
    width: 80% !important;
  }
  .domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
    white-space: normal !important;
  }
  .lesiurpackages_hotelsbox.domestic_hotelsbox .place {
    width: 80% !important;
  }
}
</style>
